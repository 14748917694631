import React, { useContext, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "@mnemonic/intl";
import { RemoveSplash } from "@mnemonic/mui5";
import { PageLayout } from "@mnemonic/mui5/src/PageLayout";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { fetchUser, updateUserPassword } from "@mne-authentication/api";
import { SessionContext } from "@mne-authentication/session2";
import ChangePasswordForm from "../Components/ChangePasswordForm";
const DEFAULT_NEXT = "/spa/dashboard";
function ChangePasswordPage() {
    const [loading, setLoading] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(false);
    const [formValues, setFormValues] = useState({
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
    });
    const [errorMessages, setErrorMessages] = useState({
        currentPasswordField: null,
        newPasswordField: null,
        repeatNewPasswordField: null,
    });
    const session = useContext(SessionContext);
    // get ?next url
    const nextUrl = window.location.search.replace("?next=", "");
    const opts = {
        apiUrl: ArgusUtils.getApiUrl(),
        sessionKey: session.session.info.sessionKey,
    };
    // Check if user should have the option to cancel password change
    useEffect(() => {
        fetchUser({ apiUrl: opts.apiUrl }).then(({ response, json }) => {
            if (response.ok) {
                setShowCancelButton(!json.data.flags.includes("FORCE_PWCHANGE"));
            }
        });
    }, []);
    const onChange = useMemo(() => (field) => (event) => {
        if (field === "currentPassword") {
            setFormValues({ ...formValues, currentPassword: event.target.value });
        }
        if (field === "newPassword") {
            setFormValues({ ...formValues, newPassword: event.target.value });
        }
        if (field === "repeatNewPassword") {
            setFormValues({ ...formValues, repeatNewPassword: event.target.value });
        }
    }, [setFormValues, formValues]);
    const isEmptyForm = (form) => {
        if (form.currentPassword === "" || form.newPassword === "" || form.repeatNewPassword === "") {
            return true;
        }
        return false;
    };
    const setEmptyFieldErrorMessage = (value) => {
        if (value === "") {
            return <FormattedMessage id="empty.field.error.message" defaultMessage="Empty field"/>;
        }
        return null;
    };
    const onSubmit = useMemo(() => () => {
        setErrorMessages({
            currentPasswordField: null,
            newPasswordField: null,
            repeatNewPasswordField: null,
        });
        if (formValues.newPassword !== formValues.repeatNewPassword) {
            setErrorMessages({
                ...errorMessages,
                newPasswordField: <FormattedMessage id="change.password.not.equal" defaultMessage="Passwords must match"/>,
                repeatNewPasswordField: (<FormattedMessage id="change.password.not.equal" defaultMessage="Passwords must match"/>),
            });
        }
        else if (isEmptyForm(formValues)) {
            setErrorMessages({
                currentPasswordField: setEmptyFieldErrorMessage(formValues.currentPassword),
                newPasswordField: setEmptyFieldErrorMessage(formValues.newPassword),
                repeatNewPasswordField: setEmptyFieldErrorMessage(formValues.repeatNewPassword),
            });
        }
        else {
            setLoading(true);
            updateUserPassword({ oldPassword: formValues.currentPassword, newPassword: formValues.newPassword }, opts).then(({ response, json }) => {
                setLoading(false);
                if (response.ok) {
                    window.location.href = decodeURIComponent(nextUrl) || DEFAULT_NEXT;
                }
                else {
                    if (json.data.reason && json.data.reason === "wrongPassword") {
                        // Set error message from backend
                        setErrorMessages({
                            currentPasswordField: json.data.message ? json.data.message : `Unknown error ${response.status}`,
                            newPasswordField: null,
                            repeatNewPasswordField: null,
                        });
                    }
                    else {
                        setErrorMessages({
                            currentPasswordField: null,
                            newPasswordField: json.data.message ? json.data.message : `Unknown error ${response.status}`,
                            repeatNewPasswordField: json.data.message ? json.data.message : `Unknown error ${response.status}`,
                        });
                    }
                }
            });
        }
    }, [formValues, errorMessages]);
    return (<PageLayout PageHeaderProps={{
            content: (<Typography variant="h6">
            {<FormattedMessage id="login.authentication" defaultMessage="Authentication"/>}
          </Typography>),
        }}>
      <RemoveSplash />
      <ChangePasswordForm showCancelButton={showCancelButton} loading={loading} onSubmit={onSubmit} onChange={onChange} errorMessages={errorMessages}/>
    </PageLayout>);
}
export default ChangePasswordPage;
