import { defineMessages } from "@mnemonic/intl";
export const commonMessages = defineMessages({
    AuthenticationFailed: { id: "AuthenticationFailed", defaultMessage: "Authentication failed" },
    Authorize: { id: "authorize", defaultMessage: "Authorize" },
    Cancel: { id: "cancel", defaultMessage: "Cancel" },
    ChangePassword: { id: "ChangePassword", defaultMessage: "Change password" },
    Customers: { id: "Customers", defaultMessage: "Customers" },
    Password: { id: "Password", defaultMessage: "Password" },
    TokenCode: { id: "tokenCode", defaultMessage: "Token code" },
    UnknownError: { id: "UnknownError", defaultMessage: "Unknown error" },
});
