import React from "react";
import { Form } from "react-final-form";
import { FormattedMessage } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import { LoginBox, LoginBoxCallout, LoginBoxContent, LoginBoxHeader, LoginBoxSubHeader, } from "../../Components/Login/Layout";
import { LoginButtonWithDisclaimer } from "../../Components/LoginButtonWithDisclaimer";
import { TextField } from "../../Components/MuiFinalForm";
import { PasswordField } from "../../Components/PasswordTextField";
import { UsernameTextField } from "../../Components/UsernameTextField";
import { useAuthenticateTotpMutation } from "../../Redux/Service/authenticateApi";
import { useTypedDispatch, useTypedSelector } from "../../Redux/createStore";
import { globalSlice } from "../../Redux/globalSlice";
import RedirectWrapper from "./RedirectWrapper";
import { StyledMultiFieldWrapper, StyledSingleFieldWrapper, StyledTokenFieldWrapper } from "./StyledComponets";
import { saveLoginDetails } from "./utils/saveLoginDetails";
import validateEmptyField from "./utils/validateEmptyField";
export const LoginTotp = () => {
    const { domain } = ArgusUtils.getTargetMeta();
    const [authenticateTotp, { isLoading, error }] = useAuthenticateTotpMutation();
    const { lastUsername } = useTypedSelector((state) => state.global);
    const dispatch = useTypedDispatch();
    const errorMessage = extractErrorMessage(error);
    return (<RedirectWrapper>
      <LoginBox loading={isLoading}>
        <LoginBoxHeader />
        <LoginBoxContent>
          {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
          <LoginBoxSubHeader method={<FormattedMessage id="login.method.totp" defaultMessage="Time-based One Time Password"/>}/>
          <Form initialValues={{ userName: lastUsername, password: null, tokenCode: null }} onSubmit={(formValues, formApi) => authenticateTotp({
            criteria: {
                userName: formValues.userName,
                password: formValues.password,
                tokenCode: formValues.tokenCode,
                domain,
            },
        })
            .unwrap()
            ?.then((payload) => {
            const isSuccess = saveLoginDetails({ username: formValues.userName, method: "totp", payload });
            dispatch(globalSlice.actions.setLoginSuccess(isSuccess));
        })
            // on error reset password field
            ?.catch((error) => {
            formApi.change("password", "");
            console.error(error);
        })} render={({ values, handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
                  {/* ARGUS-38521 - Infra uses this in an E2E Test, be aware */}
                  <StyledSingleFieldWrapper>
                    <UsernameTextField name="userName" autoFocus={!values.userName}/>
                  </StyledSingleFieldWrapper>
                  <StyledMultiFieldWrapper>
                    <PasswordField name="password" method="static" autoFocus={Boolean(values.userName)}/>
                    <StyledTokenFieldWrapper>
                      <TextField name="tokenCode" fullWidth autoComplete="off" label={<FormattedMessage id="TokenCode"/>} helperText={<FormattedMessage id="login.helper.totp.token" defaultMessage="The code displayed on your TOTP-device"/>} validate={(value) => validateEmptyField(value)}/>
                    </StyledTokenFieldWrapper>
                  </StyledMultiFieldWrapper>
                  <LoginButtonWithDisclaimer disableButton={isLoading}/>
                </form>);
        }}/>
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
