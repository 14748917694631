import * as React from "react";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FormattedMessage, useIntl } from "@mnemonic/intl";
import { Section } from "@mnemonic/mui5";
import { commonMessages } from "../commonMessages";
const ChangePasswordForm = ({ onSubmit, onChange, errorMessages, showCancelButton, loading }) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [showPassword, setShowPassword] = React.useState({ current: false, new: false, repeat: false });
    const endAdornment = (fieldId) => ({
        endAdornment: (<InputAdornment position="end">
        <IconButton onClick={() => setShowPassword({ ...showPassword, [fieldId]: !showPassword[fieldId] })} edge="end" aria-label="toggle password visibility">
          {showPassword[fieldId] ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>),
    });
    return (<Section loading={loading} sx={{ p: 2, maxWidth: "75%", m: "auto" }}>
      <Section.Header title={formatMessage(commonMessages.ChangePassword)}/>
      <Section.Content>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField id="current-password-field" InputProps={endAdornment("current")} error={errorMessages.currentPasswordField !== null} fullWidth label={<FormattedMessage id="change.password.current.field" defaultMessage="Current password"/>} onChange={onChange("currentPassword")} type={showPassword.current ? "text" : "password"}/>
              {errorMessages.currentPasswordField && (<FormHelperText error>{errorMessages.currentPasswordField}</FormHelperText>)}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField InputProps={endAdornment("new")} error={errorMessages.newPasswordField !== null} fullWidth id="new-password-field" label={<FormattedMessage id="change.password.new" defaultMessage="New password"/>} onChange={onChange("newPassword")} type={showPassword.new ? "text" : "password"} variant="outlined" helperText={<FormattedMessage id="change.password.information" defaultMessage="Passwords must be at least 8 characters long, and contain numbers as well as upper- and lowercase letters"/>}/>
              {errorMessages.newPasswordField && (<FormHelperText error>{errorMessages.newPasswordField}</FormHelperText>)}
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField id="repeat-password-field" variant="outlined" type={showPassword.repeat ? "text" : "password"} label={<FormattedMessage id="change.password.repeat" defaultMessage="Repeat new password"/>} onChange={onChange("repeatNewPassword")} error={errorMessages.repeatNewPasswordField !== null} fullWidth InputProps={endAdornment("repeat")}/>
              {errorMessages.repeatNewPasswordField && (<FormHelperText error>{errorMessages.repeatNewPasswordField}</FormHelperText>)}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button id="change-password-button" disabled={loading} size="large" color="secondary" variant="contained" onClick={() => onSubmit()}>
                <FormattedMessage id="change.password.save" defaultMessage="Save"/>
              </Button>
              {showCancelButton && (<Button id="cancel-button" size="large" color="primary" onClick={() => navigate(-1)}>
                  <FormattedMessage id="Cancel"/>
                </Button>)}
            </Grid>
          </Grid>
        </form>
      </Section.Content>
    </Section>);
};
export default ChangePasswordForm;
