import React from "react";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import { FormattedMessage } from "@mnemonic/intl";
import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { extractErrorMessage } from "@mnemonic/redux-utils";
import Disclaimer from "../../Components/GDPRDisclaimer";
import { LoginBoxCallout, LoginBoxSubHeader } from "../../Components/Login/Layout";
import { TextField } from "../../Components/MuiFinalForm";
import { UsernameTextField } from "../../Components/UsernameTextField";
import { useAuthenticateSMSMutation } from "../../Redux/Service/authenticateApi";
import { useTypedSelector } from "../../Redux/createStore";
import { StyledDisclaimerWrapper, StyledSingleFieldWrapper } from "./StyledComponets";
import validateEmptyField from "./utils/validateEmptyField";
export const LoginSMSUsernamePassword = ({ setSmsStepState }) => {
    const { domain } = ArgusUtils.getTargetMeta();
    const [authenticateSms, { isLoading, error }] = useAuthenticateSMSMutation();
    const { lastUsername } = useTypedSelector((state) => state.global);
    const errorMessage = extractErrorMessage(error);
    const initialValues = { passcode: null, userName: lastUsername };
    return (<>
      {errorMessage && <LoginBoxCallout type="error">{errorMessage}</LoginBoxCallout>}
      <LoginBoxSubHeader method={<FormattedMessage id="login.method.sms" defaultMessage="SMS"/>}/>
      <Form initialValues={initialValues} onSubmit={(formValues, formApi) => authenticateSms({
            criteria: { userName: formValues.userName, passcode: formValues.passcode, domain, mode: "PASSWORD" },
        })
            .unwrap()
            ?.then((payload) => {
            if (payload.responseCode === 206) {
                setSmsStepState({ userName: formValues.userName, cookie: payload.data.cookie, step: 2 });
            }
        })
            // on error reset password field
            ?.catch((error) => {
            formApi.change("passcode", "");
            console.error(error);
        })} render={({ values, handleSubmit }) => {
            return (<form onSubmit={handleSubmit}>
              <StyledSingleFieldWrapper>
                <UsernameTextField name="userName" autoFocus={!values.userName}/>
              </StyledSingleFieldWrapper>
              <StyledSingleFieldWrapper>
                <TextField autoComplete="current-password" autoFocus={Boolean(values.userName)} fullWidth helperText={<FormattedMessage id="login.helper.static.password" defaultMessage="Your static password"/>} label={<FormattedMessage id="Password"/>} name="passcode" type="password" validate={(value) => validateEmptyField(value)}/>
              </StyledSingleFieldWrapper>
              <StyledDisclaimerWrapper>
                <Disclaimer />
                <Button type="submit" variant="contained" color="secondary" disabled={isLoading}>
                  <FormattedMessage id="login.next" defaultMessage="Next"/>
                </Button>
              </StyledDisclaimerWrapper>
            </form>);
        }}/>
    </>);
};
