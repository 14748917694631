import React from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import LdapButton from "../../Components/LDAPButton";
import { LoginBox, LoginBoxContent, LoginBoxHeader } from "../../Components/Login/Layout";
import OpenIDButton from "../../Components/OpenIDButton";
import PasswordButton from "../../Components/PasswordButton";
import SMSButton from "../../Components/SMSButton";
import TOTPButton from "../../Components/TOTPButton";
import WebAuthnButton from "../../Components/WebAuthnButton";
import { useFetchAvailableAuthMethodQuery } from "../../Redux/Service/methodsApi";
import RedirectWrapper from "./RedirectWrapper";
export const LoginComponentMenu = () => {
    const navigate = useNavigate();
    const { data, isLoading } = useFetchAvailableAuthMethodQuery({});
    const methods = data?.data || [];
    const redirect = (path) => {
        navigate(`/login/${path}`);
    };
    return (<RedirectWrapper>
      <LoginBox loading={isLoading}>
        <LoginBoxHeader />
        <LoginBoxContent>
          {isLoading && (<List>
              {Array(5).map((_, index) => (<Skeleton key={index} variant="rectangular" height={79} sx={({ spacing }) => ({ py: spacing(), px: spacing(2) })}/>))}
            </List>)}
          <List>
            {methods.includes("LDAP") && <LdapButton onClick={() => redirect("ldap")}/>}
            {methods.includes("PASSWORD") && <PasswordButton onClick={() => redirect("password")}/>}
            {methods.includes("SMS") && <SMSButton onClick={() => redirect("sms")}/>}
            {methods.includes("TOTP") && <TOTPButton onClick={() => redirect("totp")}/>}
            {methods.includes("OPENID") && <OpenIDButton onClick={() => redirect("openid")}/>}
            {methods.includes("SIGNATURE") && window.PublicKeyCredential && (<WebAuthnButton onClick={() => redirect("webauthn")}/>)}
          </List>
        </LoginBoxContent>
      </LoginBox>
    </RedirectWrapper>);
};
