import * as ArgusUtils from "@mnemonic/pure-utils/ArgusUtils";
import { handleResponse } from "@mnemonic/redux-utils";
import { authenticateLdap, authenticatePassword, authenticateSMS, authenticateTotp, authenticateWebauthn, } from "@mne-authentication/api";
import { baseApi } from "./baseApi";
const authenticateApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        /* Mutations */
        authenticateLdap: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateLdap(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticatePassword: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticatePassword(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateTotp: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateTotp(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateSMS: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateSMS(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
        authenticateWebauthn: build.mutation({
            queryFn: ({ criteria, metaOpts }) => handleResponse(authenticateWebauthn(criteria, { apiUrl: ArgusUtils.getApiUrl() }), metaOpts),
            invalidatesTags: ["AuthenticationData"],
        }),
    }),
});
export const { useAuthenticateLdapMutation, useAuthenticatePasswordMutation, useAuthenticateTotpMutation, useAuthenticateSMSMutation, useAuthenticateWebauthnMutation, } = authenticateApi;
export default authenticateApi;
